import React from "react";
import "./MeetCompanyCard2.css";

function MeetCompanyCard({ image }) {
  return (
    <div className="meetCompanyCard2">
      <img className="meetCompanyCard2__image" src={image} alt="" />
    </div>
  );
}

export default MeetCompanyCard;
