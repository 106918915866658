import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import Contact from "./pages/contact/Contact";
import Home from "./pages/HomePage/Home";
import Portfolio from "./pages/Portfolio/Portfolio";
import Team from "./pages/Team/Team";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Home} exact />

          <Route path="/portfolio" component={Portfolio} exact />
          <Route path="/team" component={Team} exact />
          <Route path="/contact" component={Contact} exact />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
