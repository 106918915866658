import React from "react";
import "./GetStart.css";

function GetStart() {
  return (
    <div className="start_main">
    </div>
  );
}

export default GetStart;
