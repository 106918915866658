import React, { useState } from "react";
import "./Slider.css";

import design from "../../assets/slider_design.png";
import design1 from "../../assets/slider-image1.png";
import design2 from "../../assets/slider-image2.png";
import design3 from "../../assets/slide-image3.png";
import FrankEnergieLogo from "../../assets/FE-logo.png";
import GoCancelLogo from "../../assets/GoCancel-logo.png";

import next from "../../assets/arrow-next.svg";
import prev from "../../assets/arrow-prev.svg";

const quotes = [
  {
    quote:
      "You just want to work with people that have been in your shoes, and nobody has the track record that APC has in the Startup and Fintech scene. Their knowhow has been an invaluable asset in order to achieve our targets at each single level.",
    author: "Carlos Valenzuela, CEO",
    image: design1,
  },
  {
    quote:
      "APC brings so much to the table as the team is very experienced in the fintech space which makes them the perfect (sparring) partner.",
    author: "Frank Schuitemaker, CEO, Sniptech",
    image: design2,
  },
  {
    quote:
      "Working with APC, we’ve been able to get connections we never would have been able to previously. The team provides us with active support, has helped us find great technical collaboration partners and potential customers.",
    author: "Gijs de Jager, CCO, Orangebuddies",
    image: design3,
  },
  {
    quote:
      "It's a delight to work with guys that have lots of experience in fast growing start-ups.",
    author: "Thomas Hulshof, CEO, Frank Energie",
    image: FrankEnergieLogo,
  },
  {
    quote:
      "We never considered to team up with an investor until APC contacted us. After just a few talks we were convinced that our ambitious goals become feasible when teaming up with them. So far, not a second of regret.",
    author: "Kees van Nuland, CEO, GoCancel",
    image: GoCancelLogo,
  },
];

function HomeSlider() {
  const [content, setContent] = useState(0);

  const nextHandler = () => {
    if (content + 1 < quotes.length) {
      setContent(content + 1);
    } else {
      setContent(0);
    }
  };
  const prevHandler = () => {
    if (content - 1 >= 0) {
      setContent(content - 1);
    } else {
      setContent(quotes.length - 1);
    }
  };

  const { author, quote, image } = quotes[content];

  return (
    <div className="slider">
      <div className="container slider__content">
        <p className="slider__text">“{quote}”</p>
        <p className="slider__author mt-3">
          {author}
        </p>
        <div className="slider__company">
          <img className="slider__companyLogo" src={image} alt="" />
        </div>
        <div className="slider__design">
          <img src={design} alt="" />
        </div>
      </div>
      <div className="slider__arrows">
        <img
          style={{ cursor: "pointer" }}
          src={prev}
          alt=""
          className="mx-5"
          onClick={prevHandler}
        />
        <img style={{ cursor: "pointer" }} src={next} alt="" onClick={nextHandler} />
      </div>
    </div>
  );
}

export default HomeSlider;
