import React from "react";
import "./PortfolioBanner.css";

function PortfolioBanner() {
  return (
    <div className="container portfolioBanner">
      <h1 className="portfolioBanner__heading">
        Be part of the next fintech <span> success story.</span>
      </h1>
      <p className="portfolioBanner__text mt-5 pt-5">
      </p>
    </div>
  );
}

export default PortfolioBanner;
