import React from "react";
import TeamCard from "./TeamCard";
import "./TeamManagement.css";

import team1 from "../../assets/team1.png";
import team2 from "../../assets/team2.png";
import team6 from "../../assets/team6.png";
import team7 from "../../assets/team7.png";
import team8 from "../../assets/team8.png";
import team9 from "../../assets/team9.png";
import whiteDesign from "../../assets/meet-image-white.png";

function TeamManagement() {
  return (
    <div className="teamManagement">
      <div className="container">
        <h2 className="teamManagement__heading mb-5">
          Meet our Management Team
        </h2>
        <p className="teamManagement__text mb-5">
          We invest in the brilliant people that will bring the best expertise
          from finance to marketing and tech, to help you grow.
        </p>
        <div className="row">
          <div className="col-md-4 ">
            <TeamCard
              image={team1}
              name="Jasper Anderluh"
              post="Managing Partner"
            />
          </div>
          <div className="col-md-4">
            <TeamCard image={team2} name="Niels Klok" post="Managing Partner" />
          </div>
          <div className="col-md-4">
            <TeamCard image={team6} name="Florea Parauan" post="Technology" />
          </div>
          <div className="col-md-4">
            <TeamCard image={team7} name="Coen Stipdonk" post="Operations" />
          </div>
          <div className="col-md-4">
            <TeamCard
              image={team8}
              name="Hans van der Woude"
              post="Acquisition, finance and legal"
            />
          </div>
          <div className="col-md-4 team9">
            <TeamCard image={team9} name="Jochem van Engers" post="Product" />
          </div>
        </div>
      </div>
      <div className="team__managementImageContainer">
        <img src={whiteDesign} alt="" className="team__managementImage" />
      </div>
    </div>
  );
}

export default TeamManagement;
