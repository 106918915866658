import React from "react";
import "./MeetCompanyCard3.css";

function MeetCompanyCard({ image }) {
  return (
    <div className="meetCompanyCard3">
      <img className="meetCompanyCard3__image" src={image} alt="" />
    </div>
  );
}

export default MeetCompanyCard;
