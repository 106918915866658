import React from "react";
import "./MeetCompanyCard.css";

function MeetCompanyCard({ image }) {
  return (
    <div className="meetCompanyCard">
      <img className="meetCompanyCard__image" src={image} alt=""/>
    </div>
  );
}

export default MeetCompanyCard;
