import React from "react";
import "./HomeBanner.css";
import bulb from "../../assets/home-bulb.png";



function HomeBanner() {
  return (
    <div className="homeBanner ">
      <div className="container">
        <h1 className="homeBanner__heading">
          To us, supporting fintech starts with investing in
          <span> people.</span>
        </h1>
      </div>

      <div className="container-fluid b-Wraper">

        <div className="circle"> 
          <div className="black-full">
            <div className="container b-content">

              <div className="row">
                  <div className="col text-col">
                    <h1 className="start__heading">When did we get started?</h1>
                    <p className="start__content">Founded in 2020, Amsterdam Platform Creation (APC) supports in their growth fintech firms that deliver best in class financial related services. Our experienced and talented experts help these firms to become better and build it into something bigger.</p>
                    </div>
                  <div className="col"><img src={bulb} alt="" className="start__image" /></div>
              </div>
            </div> 

          </div>
        </div>
      </div>




    </div>
  );
}

export default HomeBanner;
